// src/components/YearTabs.js
import React from 'react';
import { Tabs, Tab, Box } from '@mui/material';

const years = ['2023', '2024', '2025'];

const YearTabs = ({ selectedYear, handleYearChange }) => {
  return (<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs
        value={selectedYear}
        onChange={handleYearChange}
        aria-label="Year Tabs"
        centered
      >
        {years.map((year) => (<Tab key={year} label={year} value={year}/>))}
      </Tabs>
    </Box>);
};

export default YearTabs;
