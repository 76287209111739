// src/pages/Login.js
import React, { useState } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import axiosInstance from '../api/axiosInstance'; // Use the custom Axios instance
import { Navigate } from 'react-router-dom';
import { Container, TextField, Button, Typography, Box, Paper, Alert } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';

const Login = () => {
  const { isAuthenticated, login } = useAuth(); // Access login method from AuthContext
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);

  // Redirect authenticated users away from login
  if (isAuthenticated) {
    return <Navigate to="/" replace/>;
  }

  // Handle Google Sign-In success
  const handleGoogleSuccess = async (response) => {
    console.log('Google Login success:', response);
    const { credential } = response;
    try {
      // Send the Google ID token to your backend for verification and token issuance
      const res = await axiosInstance.post('auth/google/', {
        token: credential,
      });
      // Use the login method from AuthContext
      login(res.data.token);
    } catch (error) {
      console.error('Google Login error:', error);
      setError('Google Login failed. Please try again.');
    }
  };

  // Handle Google Sign-In failure
  const handleGoogleError = () => {
    setError('Google Login failed. Please try again.');
  };

  // Handle username/password login
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Send username and password to backend
      const res = await axiosInstance.post('login/', {
        username, password,
      });
      // Use the login method from AuthContext
      login(res.data.token);
    } catch (error) {
      console.error('Login error:', error);
      setError('Invalid username or password.');
    }
  };

  return (<Container maxWidth="xs">
      <Paper elevation={3} sx={{ padding: 3, marginTop: 8 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Login
        </Typography>

        {/* Error Alert */}
        {error && (<Alert severity="error" sx={{ marginBottom: 2 }}>
            {error}
          </Alert>)}

        {/* Username/Password Form */}
        <form onSubmit={handleSubmit}>
          <Box mb={2}>
            <TextField
              label="Username"
              variant="outlined"
              fullWidth
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </Box>
          <Box mb={2}>
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Box>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Login
          </Button>
        </form>

        <Box my={2} textAlign="center">
          <Typography variant="body1">or</Typography>
        </Box>

        {/* Google Login Button */}
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <Box display="flex" justifyContent="center">
            <GoogleLogin onSuccess={handleGoogleSuccess} onError={handleGoogleError}/>
          </Box>
        </GoogleOAuthProvider>
      </Paper>
    </Container>);
};

export default Login;
