// src/components/MonthTabs.js
import React from 'react';
import { Tabs, Tab, Box } from '@mui/material';

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December',];

const MonthTabs = ({ selectedMonth, handleMonthChange }) => {
  return (<Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 2 }}>
      <Tabs
        value={selectedMonth}
        onChange={handleMonthChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="Month Tabs"
        centered
      >
        {months.map((month, index) => (<Tab
            key={month}
            label={month}
            value={index + 1} // Months as numbers 1-12
          />))}
      </Tabs>
    </Box>);
};

export default MonthTabs;
