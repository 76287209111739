// src/components/ScreenshotUpload.js
import React, { useState, useRef } from 'react';
import { Box, Typography, Button, CircularProgress, Alert } from '@mui/material';
import axiosInstance from '../api/axiosInstance';
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-toastify';

const ScreenshotUpload = ({ onUploadSuccess }) => {
  const { isAuthenticated } = useAuth(); // Access authentication status if needed
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isDragOver, setIsDragOver] = useState(false);
  const fileInputRef = useRef(null);

  const handlePaste = async (event) => {
    const items = event.clipboardData.items;
    for (let item of items) {
      if (item.type.startsWith('image/')) {
        const file = item.getAsFile();
        await uploadImage(file);
        event.preventDefault(); // Prevent the default paste behavior
        return;
      }
    }
    setError('No image found in the pasted content.');
    toast.error('No image found in the pasted content.');
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    setIsDragOver(false);
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      if (file.type.startsWith('image/')) {
        await uploadImage(file);
      } else {
        setError('Only image files are allowed.');
        toast.error('Only image files are allowed.');
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDragOver(false);
  };

  const handleFileSelect = async (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      const file = files[0];
      if (file.type.startsWith('image/')) {
        await uploadImage(file);
      } else {
        setError('Only image files are allowed.');
        toast.error('Only image files are allowed.');
      }
    }
  };

  const uploadImage = async (file) => {
    setLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append('screenshot', file);

    try {
      const response = await axiosInstance.post('send_screenshot/', formData, {
        headers: {
          // 'Content-Type' is set automatically by Axios for FormData
          'Content-Type': 'multipart/form-data',
        },
      });

      toast.success('Screenshot uploaded successfully!');
      if (onUploadSuccess) {
        onUploadSuccess();
      }
    } catch (err) {
      console.error(err);
      setError('Failed to upload screenshot.');
      toast.error('Failed to upload screenshot.');
    } finally {
      setLoading(false);
    }
  };

  return (<Box
      sx={{
        border: '2px dashed #ccc',
        borderRadius: '8px',
        padding: '20px',
        textAlign: 'center',
        color: '#666',
        mb: 4,
        outline: 'none',
        backgroundColor: isDragOver ? '#f0f0f0' : 'transparent',
        transition: 'background-color 0.3s',
      }}
      onPaste={handlePaste}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      tabIndex={0} // Make the box focusable to capture paste events
    >
      <Typography variant="h6" gutterBottom>
        Paste your screenshot here (Ctrl+V) or click to select an image
      </Typography>
      <Button
        variant="contained"
        component="label"
        disabled={loading}
        sx={{ mt: 2 }}
      >
        Select Image
        <input
          type="file"
          accept="image/*"
          hidden
          onChange={handleFileSelect}
          ref={fileInputRef}
        />
      </Button>
      {loading && (<Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <CircularProgress/>
        </Box>)}
      {error && (<Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>)}
    </Box>);
};

export default ScreenshotUpload;
