// src/api/axiosInstance.js
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://api.amhajja.com/api/', headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to include the token
axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Token ${token}`; // DRF Token Authentication
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Optional: Add a response interceptor to handle errors globally
axiosInstance.interceptors.response.use((response) => response, (error) => {
  // Handle unauthorized errors
  if (error.response && error.response.status === 401) {
    // Implement logout or token refresh if needed
    localStorage.removeItem('token');
    window.location.href = '/login';
  }

  return Promise.reject(error);
});

export default axiosInstance;
