// src/components/ExpensesList.js
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  CircularProgress,
  Alert,
  Box,
  IconButton,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axiosInstance from '../api/axiosInstance';
import { toast } from 'react-toastify';

const ExpensesList = ({ expenses, loading, error, fetchExpenses }) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedExpenseId, setSelectedExpenseId] = React.useState(null);

  const handleDeleteClick = (expenseId) => {
    setSelectedExpenseId(expenseId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedExpenseId(null);
  };

  const handleConfirmDelete = async () => {
    try {
      await axiosInstance.delete(`delete_expense/${selectedExpenseId}/`);
      toast.success('Expense deleted successfully!');
      fetchExpenses(); // Refetch expenses after deletion
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status === 404) {
        toast.error('Expense not found.');
      } else if (err.response && err.response.status === 401) {
        toast.error('Unauthorized. Please log in again.');
      } else {
        toast.error('Failed to delete expense. Please try again.');
      }
    } finally {
      handleCloseDialog();
    }
  };

  if (loading) {
    return (<Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress/>
      </Box>);
  }

  if (error) {
    return (<Alert severity="error" sx={{ mt: 4 }}>
        {error}
      </Alert>);
  }

  if (expenses.length === 0) {
    return (<Typography variant="h6" align="center" sx={{ mt: 4 }}>
        No expenses found for this month.
      </Typography>);
  }

  return (<>
      <TableContainer component={Paper} sx={{ mt: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Description</strong>
              </TableCell>
              <TableCell align="right">
                <strong>Category</strong>
              </TableCell>
              <TableCell align="right">
                <strong>Amount ($)</strong>
              </TableCell>
              <TableCell align="right">
                <strong>Date</strong>
              </TableCell>
              <TableCell align="right">
                <strong>Location</strong>
              </TableCell>
              <TableCell align="center">
                <strong>Actions</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {expenses.map((expense) => (<TableRow key={expense.id}>
                <TableCell>{expense.description}</TableCell>
                <TableCell align="right">{expense.category}</TableCell>
                <TableCell align="right">
                  {parseFloat(expense.amount).toFixed(2)}
                </TableCell>
                <TableCell align="right">
                  {new Date(expense.datetime).toLocaleDateString()}
                </TableCell>
                <TableCell align="right">{expense.location}</TableCell>
                <TableCell align="center">
                  <Tooltip title="Delete Expense">
                    <IconButton
                      color="error"
                      onClick={() => handleDeleteClick(expense.id)}
                    >
                      <DeleteIcon/>
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Confirmation Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="delete-expense-dialog-title"
        aria-describedby="delete-expense-dialog-description"
      >
        <DialogTitle id="delete-expense-dialog-title">
          {"Delete Expense"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-expense-dialog-description">
            Are you sure you want to delete this expense? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>);
};

export default ExpensesList;
